/* App CSS by Abz */

:root {
  --blue: #2a2a46;
  --grey: #f1f3f4;
  --green: #10b981;
  --red: #ef4444;
  --yellow: #f2ff00;
  --golden: #ffa800;
  --light-grey: #dbdbdb;
  --dark-grey: #dfe3e6;
  --font-inter-black: "Inter-Black";
  --font-inter-bold: "Inter-Bold";
  --font-inter-extrabold: "Inter-ExtraBold";
  --font-inter-extralight: "Inter-ExtraLight";
  --font-inter-light: "Inter-Light";
  --font-inter-medium: "Inter-Medium";
  --font-inter-regular: "Inter-Regular";
  --font-inter-semibold: "Inter-SemiBold";
  --font-inter-thin: "Inter-Thin";
  --font-stretch-pro: "Stretch-Pro";
  --font-trap: "Trap";

  --235EE8: #235ee8;
}

.--pt-33 {
  padding-top: 33px !important;
}

.--position-relative {
  position: relative !important;
}

.--position-absolute-top-center {
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.--font-trap {
  font-family: Trap !important;
}

.--font-stretch-pro {
  font-family: "Stretch-Pro" !important;
}

.--md-mt-30 {
  @media (max-width: 767.99px) {
    margin-top: 30px !important;
  }
}

.--bg-img-signon {
  background: url("./assets/images//bg//bg-signon4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.--font-weight-700 {
  font-weight: 700 !important;
}

.--font-weight-900 {
  font-weight: 900 !important;
}

.--text-navy {
  color: #2a2a46 !important;
}

.--bg-navy {
  background-color: #2a2a46 !important;
}

.--text-12 {
  font-size: 12px;
}

.--text-14 {
  font-size: 14px;
}

.--text-16 {
  font-size: 16px;
}

.--border-solid-2-ef4444 {
  border: 2px solid #ef4444;
}

.--text-18 {
  font-size: 12px;
}

.--text-20 {
  font-size: 20px;
}

.--text-24 {
  font-size: 24px;
}

.--text-36 {
  font-size: 36px;
}

.--text-65 {
  font-size: 65px;
}

.--text-gold {
  color: #ffd15c;
}

.--text-diamond {
  color: #6fa8ad;
}

body {
  overflow: hidden;
}

.blue-bg {
  background: var(--blue) !important;
}

.grey-bg {
  background: var(--grey) !important;
}

.green-bg {
  background: var(--green) !important;
}

.red-bg {
  background: var(--red) !important;
}

.yellow-bg {
  background: var(--yellow) !important;
}

.golden-bg {
  background: var(--golden) !important;
}

.light-grey-bg {
  background: var(--light-grey) !important;
}

.blue-text {
  color: var(--blue) !important;
}

.blue-text {
  color: var(--blue) !important;
}

.grey-text {
  color: var(--grey) !important;
}

.green-text {
  color: var(--green) !important;
}

.yellow-text {
  color: var(--yellow) !important;
}

.golden-text {
  color: var(--golden) !important;
}

.red-text {
  color: var(--red) !important;
}

.text-808080 {
  color: #808080;
}

.text-235EE8 {
  color: var(--235EE8);
}

.blue-border {
  border-color: var(--blue) !important;
}

.grey-border {
  border-color: var(--grey) !important;
}

.red-border {
  border-color: var(--red) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: var(--font-inter-regular), sans-serif !important;
}

.font--inter-black {
  font-family: "Inter-Black" !important;
}

.font--inter-bold {
  font-family: "Inter-Bold" !important;
}

.font--inter-extrabold {
  font-family: "Inter-ExtraBold" !important;
}

.font--inter-extralight {
  font-family: "Inter-ExtraLight" !important;
}

.font--inter-light {
  font-family: "Inter-Light" !important;
}

.font--inter-medium {
  font-family: "Inter-Medium" !important;
}

.font--inter-regular {
  font-family: "Inter-Regular" !important;
}

.font--inter-semiBold {
  font-family: "Inter-SemiBold" !important;
}

.font--inter-thin {
  font-family: "Inter-Thin" !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-10 {
  font-size: 10px !important;
}

.line-height-normal {
  line-height: normal;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.border-radius-25 {
  border-radius: 25px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar-menu-items {
  height: calc(100vh - 100px);
}

.login-form-container {
  width: 500px;
}

form#login-form input {
  border: 0;
  border-bottom: 1px solid #000 !important;
  background-color: transparent !important;
  box-shadow: 0 0 0 50px white inset !important;
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  font-family: var(--font-inter-bold);
}

form#login-form button,
form#login-form a {
  transition: 0.2s ease-in-out;
}

form#login-form button:hover,
form#login-form a:hover {
  color: var(--blue) !important;
  background: var(--grey) !important;
}

#sidebar {
  transition: 0.2s ease-in-out;
  z-index: 9999;
}

#sidebar[data-show="show"] {
  width: 300px;
}

#sidebar[data-show="hide"] {
  width: 100px;
  padding: 10px !important;
}

.menu-toggle-btn {
  min-width: 30px;
  filter: invert(1);
}

div#sidebar .sidebar-logo img {
  transition: 0.2s ease-in-out;
  width: 75%;
}

div#sidebar.active .sidebar-logo img.active {
  display: block;
}

div#sidebar.active .sidebar-logo img.inactive {
  display: none;
}

div#sidebar.inactive .sidebar-logo img.active {
  display: none;
}

div#sidebar.inactive .sidebar-logo img.inactive {
  display: block;
  width: 100%;
  max-width: 38px;
}

div#sidebar.inactive .menu-toggle-btn {
  display: block;
  margin: 0 auto;
}

button.menu-toggle-btn.inactive {
  width: 36px !important;
}

div#sidebar .sidebar-menu-item span {
  transition: 0.2s ease-in-out;
}

div#sidebar.inactive .sidebar-menu-item {
  justify-content: center;
}

div#sidebar.inactive .sidebar-menu-item img {
  margin-right: 0 !important;
}

div#sidebar img {
  width: 20px;
}

div#sidebar.inactive .sidebar-menu-item span,
div#sidebar.inactive .support-div p {
  display: none;
}

.header-div {
  height: 70px;
}

.main-content-container {
  height: calc(100vh - 70px);
  overflow: auto;
}

.sidebar-menu-item.active {
  background: #fff;
}

.sidebar-menu-item.active img {
  filter: invert(1);
}

.sidebar-menu-item.active span {
  color: var(--blue);
}

.header-buttons > a,
.header-buttons > button {
  border-width: 3px !important;
  border-style: solid !important;
  text-align: center;
}

.support-div {
  background: rgba(255, 255, 255, 0.25);
}

.ds-link > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.js-stat-div p {
  letter-spacing: 1px;
}

.ds-tabs,
.wm-tabs {
  border: 0 !important;
}

.ds-tabs li.nav-item button {
  font-family: var(--font-inter-semibold);
  color: #808080;
  border: 0 !important;
}

.wm-tabs li.nav-item button {
  color: #808080;
  border: 0 !important;
}

.wm-tabs li.nav-item button.active {
  color: #fff !important;
  background: #6366f1;
  border-radius: 15px !important;
}

.ds-tabs li.nav-item button.active {
  color: var(--blue);
  border-bottom: 3px solid var(--blue) !important;
}

.jobs-main {
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.job-card-title {
  width: 100%;
}

.job-card-title h5 {
  height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.job-meta > span {
  text-transform: capitalize;
}

.job-meta > span[data-type="full time"] {
  color: #10b981;
  background: #cff1e6;
}

.job-meta > span[data-type="contract"] {
  color: #ef4444;
  background: #fee2e2;
}

.job-meta > span[data-type="part time"] {
  color: #10b981;
  background: #cff1e6;
}

.job-meta > span[data-type="basic"] {
  color: rgba(254, 184, 86, 1);
  background: rgba(255, 209, 92, 0.2);
  filter: brightness(0.85);
}

.job-meta > span[data-type="premium"] {
  color: rgba(111, 168, 173, 1);
  background: rgba(109, 206, 252, 0.2);
  filter: brightness(0.85);
}

.job-meta > span[data-type="internship"] {
  color: #3b82f6;
  background: #e0f2fe;
}

.job-meta > span[data-type="freelance"] {
  color: #ffa800;
  background: #ffa80033;
}

.wm-tab {
  max-height: 250px;
  overflow: auto;
}

.status-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.filters span.input-group-text {
  background: transparent;
  border-left: 0;
}

.filters input {
  border-right: 0;
  font-size: 14px;
}

.filters label {
  font-size: 14px;
}

.filter-overlay {
  background: rgba(255, 255, 255, 0.9);
}

.filter-tabs,
.filter-tabs li,
.filter-tabs button {
  border: 0 !important;
}

.filter-tabs button {
  color: #808080;
  font-size: 18px;
}

.filter-tabs button.nav-link.active,
.filter-tabs button.nav-link:focus,
.filter-tabs button.nav-link:hover {
  font-weight: bold !important;
  color: var(--blue) !important;
  border-bottom: 3px solid var(--blue) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.applicants-grid {
  display: grid !important;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.open-to-span {
  background: #10b98133;
}

.login-layout-bg {
  background: url("../public/assets/images/Blue\ Gradient.svg") no-repeat center
    center;
  background-size: cover;
}

.ls-form {
  font-family: var(--font-inter-semibold);
}

.ls-form input {
  outline: none !important;
  background: transparent !important;
  margin-bottom: 0em;
  /* border-radius: 5px !important; */
  border-color: var(--light-grey);
  font-size: 16px;
  box-shadow: none !important;
  font-family: var(--font-inter-regular) !important;
}

.ls-form input::placeholder {
  font-weight: normal !important;
}

.social-login-btn img {
  width: 40px;
}

button.bg-transparent.social-login-btn:hover {
  background-color: unset !important;
  color: unset !important;
}

.social-login-container > div {
  padding: 0;
  width: auto;
}

.w-200px {
  width: 200px;
}

.w-50px {
  width: 50px;
}

.h-50px {
  height: 50px;
}

.bg-blue {
  background: var(--blue) !important;
  transition: 0.2s ease-in-out;
}

.border-3-solid-blue {
  border: 3px solid var(--blue);
}

.billing-jobs-div {
  display: grid !important;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.cc-data-table .rdt_TableHeadRow {
  font-size: 20px;
  font-family: var(--font-inter-semibold);
}

.cc-data-table .rdt_TableRow {
  font-size: 16px;
  font-family: var(--font-inter-regular);
}

.cc-data-table .rdt_TableRow > div {
  padding: 25px 10px;
}

.cc-data-table .rdt_TableRow > div:nth-child(1),
.cc-data-table .rdt_TableRow > div:nth-last-child(1) {
  font-family: var(--font-inter-semibold);
}

#create-job-form .form-control,
select[name="template-select"] {
  min-height: 40px;
}

#create-job-form textarea.form-control {
  min-height: 120px;
}

#create-job-form .form-control,
#create-job-form input::placeholder,
#create-job-form textarea::placeholder,
#create-job-form select,
#create-job-form select option:disabled {
  color: var(--blue) !important;
  font-family: var(--font-inter-medium);
  font-size: 16px;
  box-shadow: none !important;
}

.border-right-1px-solid-grey {
  border-right: 1px solid var(--grey);
}

.border-3px-solid {
  border: 3px solid;
}

.count-token-div button {
  width: 75px;
  height: 75px;
  border-color: var(--dark-grey);
}

.count-token-div input {
  height: 75px;
  border-color: var(--dark-grey);
}

.count-token-div,
.count-token-text {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.hide {
  display: none;
}

.border-3px-dashed {
  border: 3px dashed;
}

.user-carousel span[type="prev"] {
  left: -15px;
  box-shadow: none !important;
  opacity: 1 !important;
}

.user-carousel span[type="prev"]:before {
  width: 15px;
  height: 15px;
  border-width: 3px !important;
  border-color: var(--blue) !important;
}

.user-carousel span[type="next"] {
  right: -15px;
  box-shadow: none !important;
  opacity: 1 !important;
}

.user-carousel span[type="next"]:before {
  width: 15px;
  height: 15px;
  border-width: 3px !important;
  border-color: var(--blue) !important;
}

.meeting-calendar-main button.react-calendar__tile {
  border-radius: 50%;
  height: 50px;
  font-family: var(--font-inter-regular);
  font-size: 14px;
  color: var(--blue);
  background: transparent !important;
}

.meeting-calendar-main
  .react-calendar__month-view__days
  button.react-calendar__tile--active {
  background: #4a3aff !important;
  color: #fff !important;
}

.meeting-calendar-main .react-calendar__month-view__weekdays__weekday abbr {
  color: #9291a5;
  line-height: normal;
  text-decoration: none;
  text-transform: capitalize !important;
  font-family: var(--font-inter-medium);
}

.job-applicants-table .rdt_TableHeadRow > div:nth-child(1),
.job-applicants-table .rdt_TableRow > div:nth-child(1) {
  padding: 0 !important;
  min-width: auto !important;
  max-width: 50px !important;
}

.job-applicants-table
  .rdt_TableHeadRow
  > div:nth-child(1)
  input[type="checkbox"],
.job-applicants-table .rdt_TableRow > div:nth-child(1) input[type="checkbox"] {
  width: 40px;
  height: 40px;
}

.rdt_TableCol_Sortable span {
  margin-left: 5px;
  opacity: 1 !important;
}

.user-job-status {
  font-family: var(--font-inter-medium);
  font-size: 16px !important;
  text-align: center;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  appearance: none !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.user-job-status.Pending {
  color: #ffffff !important;
  background-color: rgba(128, 128, 128, 1) !important;
}

.user-job-status.Shortlisted {
  color: rgb(248, 200, 5) !important;
  background-color: rgba(248, 200, 5, 0.2) !important;
}

.user-job-status.Interviewed {
  color: #808080 !important;
  background-color: rgba(128, 128, 128, 0.2) !important;
}

.user-job-status.Offered {
  color: #10b981 !important;
  background-color: rgba(16, 185, 129, 0.2) !important;
}

.user-job-status.Notified {
  color: #10b981 !important;
  background-color: rgba(16, 185, 129, 0.2) !important;
}

.user-job-status.Unsuccessful {
  color: rgb(165, 29, 42) !important;
  background-color: rgba(165, 29, 42, 0.2) !important;
}

.user-job-status option {
  background: #ffffff !important;
  color: #000000 !important;
}

.z-9999 {
  z-index: 9999;
}

select.form-control {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.form-control {
  min-height: 40px;
}

button.star-rating {
  font-size: 30px;
  line-height: normal;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

button.star-rating.on {
  color: #ffeb00;
}

button.star-rating.off {
  color: #dbdbdb;
}

table.close-job-reason-tbl {
  width: 100%;
}

table.close-job-reason-tbl tr td {
  padding-bottom: 10px;
  font-family: var(--font-inter-regular);
}

.cc-data-table.job-applicants-table
  .rdt_TableHeadRow
  > .rdt_TableCol:last-child,
.cc-data-table.job-applicants-table .rdt_TableRow > .rdt_TableCell:last-child {
  max-width: 10px !important;
}

.notifications-btn::after {
  display: none !important;
}

.notifications-main {
  min-width: 300px !important;
  /* transform: translate(-275px, 30px) !important; */
}

span.red-new {
  width: 6px;
  height: 6px;
  background: var(--red);
  display: inline-block;
  margin-left: 10px;
  border-radius: 50px;
}

span.red-new.bell-new {
  position: absolute;
  bottom: -5px;
  left: -5px;
  margin: 0;
  width: 8px;
  height: 8px;
}

/* .main-content-container .content {
  max-width: 1200px;
  margin: auto;
} */

@media screen and (max-width: 551px) {
  .jobs-main {
    grid-template-columns: repeat(1, 1fr);
  }
}

div#educational_level,
div#location {
  padding: 0;
}

div#educational_level > div,
div#location > div {
  border: 0 !important;
}

.delete-account-btn,
.save-account-btn {
  width: 160px;
}

span.separator {
  font-size: 30px;
  margin: 0 0.5rem;
}

.select-user-profile-phone-prefix {
  height: 40px;
  width: 60px;
  border-color: rgb(206, 212, 218);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: #ffffff;
}
.select-user-profile-phone-prefix:hover {
  background: #ffffff !important;
  color: #000000 !important;
}

ul.token-features {
  /* min-height: 192px; */
  margin: 0;
  padding-left: 16px;
}
ul.token-features.dashed {
  list-style-type: none;
}
ul.token-features.dashed > li {
  padding-left: 10px;
  text-indent: -10px;
}
ul.token-features.dashed > li:before {
  content: "-";
  text-indent: -5px;
}

#search_and_shortlist {
  box-shadow: none;
}

.editorClassName {
  background-color: #ffffff !important;
  border: solid 1px rgb(206, 212, 218) !important;
  height: 250px !important;
  margin-bottom: 25px !important;
  border-radius: 6px !important;
  padding: 6px 12px;
  margin: 0 0 24px;
}

.toolbarClassName .rdw-option-wrapper {
  color: black !important;
}

div[role="cell"]:nth-child(2) > div {
  width: 100% !important;
  margin-left: 45px !important;
}

div[role="cell"]:nth-child(3) > div {
  width: 100%;
}

div[role="cell"]:nth-child(3) > div > p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

div[role="cell"]:nth-child(3) > div > p > img {
  width: 18px;
  height: 20px;
}

.container-token-details {
  padding: 24px 36px;
  background: #ffffff;
}

.btn-height-42 {
  display: block;
  height: 42px !important;
  max-height: 42px !important;
}

#educational_level [bis_skin_checked] {
  z-index: 2 !important;
}

.editorClassName,
.editorClassName > * {
  z-index: 1 !important;
}

.hover-dark {
  background: transparent;
}
.hover-dark:hover {
  background: #eeeeee;
}

.container-insufficient-tokens {
  margin-top: 30px;
  margin-bottom: 40px;
  background-color: #ef4444;
  padding: 20px 30px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
}

.container-insufficient-tokens > span:first-child {
  font-size: 36px;
  font-weight: 700;
}

canvas {
  min-width: 100vw !important;
  min-height: 100vh !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
}
